import ScrollReveal from "scrollreveal";
const delay = 350;

ScrollReveal().reveal(".sr-bottom", {
	delay: delay,
	duration: 1000,
	distance: "15px",
	origin: "bottom",
});

ScrollReveal().reveal(".sr-right", {
	delay: delay,
	duration: 1000,
	distance: "15px",
	origin: "right",
});

ScrollReveal().reveal(".sr-left", {
	delay: delay,
	duration: 1000,
	distance: "15px",
	origin: "left",
});

import "jquery-drawer";

$(".drawer").drawer({
	iscroll: {
		mouseWheel: false,
	},
});

// Hover Setting on Nav
jQuery(function($) {
	var windowWidth = window.innerWidth;
	if (windowWidth <= 1200) {
		$(".drawer-hover").on("click", function() {
			$(this).find(".drawer-plus").toggleClass("open");
			$(this)
				.find(".drawer-hover-content")
				.css("padding-top", "10px")
				.slideToggle();
		});
	} else {
		$(".drawer-hover").hover(
			function() {
				$(this).find(".drawer-hover-content").addClass("dHover");
			},
			function() {
				$(this).find(".drawer-hover-content").removeClass("dHover");
			},
		);
	}
});

// Add Current Style on Nav

// var pageURL = location.pathname;
// var pageURLArr = pageURL.split("/");
// var pageURLArrRoot = pageURLArr[2];
// var pageURLArrCategory = pageURLArr[3];
// console.log(pageURLArrCategory);

// $(".drawer-link").each(function (i, v) {
//   var selfhref = $(v).attr("href");
//   var hrefArr = selfhref.split("/");
//   console.log(hrefArr);
//   var hrefArrCategory = hrefArr[1];

//   if (pageURLArrCategory === hrefArrCategory) {
//     $(v).addClass("current");
//   }
// });

// if (
//   pageURLArrCategory === "company" ||
//   pageURLArrCategory === "history" ||
//   pageURLArrCategory === "office" ||
//   pageURLArrCategory === "environment" ||
//   pageURLArrCategory === "items"
// ) {
//   $(".info").addClass("drawer-link current");
// } else if (pageURLArrRoot === "info") {
//   $(".info").addClass("drawer-link current");
// }
// if (
//   pageURLArrCategory === "cardboard" ||
//   pageURLArrCategory === "anti-covid" ||
//   pageURLArrCategory === "polyethylene" ||
//   pageURLArrCategory === "steel-pallet"
// ) {
//   $(".product").addClass("drawer-link current");
// } else if (pageURLArrRoot === "product") {
//   $(".product").addClass("drawer-link current");
// }

// Add Color on Nav
var heroHeight = $(".hero").height() - 88;

$(window).scroll(function() {
	var top = $(window).scrollTop();
	if (heroHeight < top) {
		$(".drawer-nav").addClass("heroHeight");
		// $("#drawer-sp__wrapper").addClass("drawer-sp__wrapper");
		$(".drawer-hamburger").addClass("underHero");
		$(".drawer-pc .logo").addClass("showLogo");
		$(".drawer-pc h1").addClass("show");
		$(".drawer-menu-item").addClass("show");
		$(".float-btn").addClass("showBtn");
		$(".navigation_search_form").addClass("show");
	} else {
		$(".drawer-nav").removeClass("heroHeight");
		$("#drawer-sp__wrapper").removeClass("drawer-sp__wrapper");
		$(".drawer-hamburger").removeClass("underHero");
		$(".drawer-pc .logo").removeClass("showLogo");
		$(".drawer-pc h1").removeClass("show");
		$(".drawer-menu-item").removeClass("show");
		$(".navigation_search_form").removeClass("show");
		// $(".float-btn").removeClass("showBtn");
	}
});

if ($(".hero-child").length) {
	// var heroHeightChild = $(".hero-child").height() - 88;
	var heroHeightChild = 100;

	$(window).scroll(function() {
		var topChild = $(window).scrollTop();
		if (heroHeightChild < topChild) {
			$(".drawer-nav").addClass("heroHeight");
			$(".drawer-pc .logo").addClass("showLogo");
			$(".drawer-pc h1").addClass("show");
			$(".navigation_search_form").addClass("show");
		} else {
			$(".drawer-nav").removeClass("heroHeight");
			$(".drawer-pc .logo").removeClass("showLogo");
			$(".drawer-pc h1").removeClass("show");
			$(".navigation_search_form").removeClass("show");
		}
	});
}

import { Tooltip, Toast, Popover } from "bootstrap";
import "bootstrap";
var hash = document.location.hash;

// if (hash) {
//   $('.nav-tabs a[href="' + hash + '"]').tab("show");
// }
// $('a[data-toggle="tab"]').on("shown.bs.tab", function (e) {
//   e.target;
//   e.relatedTarget;
// });

// if (hash) {
//   $('.nav-pills a[href="' + hash + '"]').tab("show");
// }
// $('a[data-toggle="tab"]').on("shown.bs.tab", function (e) {
//   e.target;
//   e.relatedTarget;
// });


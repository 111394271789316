!(function () {
	const viewport = document.querySelector('meta[name="viewport"]');
	function switchViewport() {
		const value =
			window.outerWidth > 375
				? "width=device-width,initial-scale=1"
				: "width=375";
		if (viewport.getAttribute("content") !== value) {
			viewport.setAttribute("content", value);
		}
	}
	addEventListener("resize", switchViewport, false);
	switchViewport();
})();

$(function () {
	if ($(".error")[0]) {
		$(".mw_wp_form").addClass("mw_wp_form_error");
		var errorEl = $(".mw_wp_form").eq(0);
		var position = errorEl.parent().offset().top - 300; //740の部分は上からなんpxの位置にスクロールしたいかを指定する。
		$("body,html")
			.delay(200)
			.animate({ scrollTop: position }, 600, "swing");
	}
});

import Swiper from "swiper/bundle";
import SwiperCore, { Navigation, Pagination, Scrollbar } from "swiper/core";
SwiperCore.use([Navigation, Pagination, Scrollbar]);
const mySwiper = new Swiper(".swiper-hero", {
	effect: "fade",
	loop: true,
	speed: 1000,
	slidesPerView: 1,
	// spaceBetween: 30,
	// grabCursor: true,
	// keyboard: {
	//   enabled: true,
	//   onlyInViewport: true,
	// },
	// watchOverflow: true,
	// roundLengths: true,
	autoplay: {
		delay: 4000,
	},
});

const caseSlider = new Swiper(".caseSlider.swiper-container", {
	navigation: {
		nextEl: ".swiper-button-next",
		prevEl: ".swiper-button-prev",
	},
	pagination: {
		el: ".swiper-pagination",
		type: "bullets",
	},
	loop: true,
	centeredSlides: true,
	autoplay: {
		delay: 3000,
	},
	autoHeight: false,
	grabCursor: true,
	breakpoints: {
		1200: {
			slidesPerView: 4.5,
			spaceBetween: 20,
		},
		992: {
			slidesPerView: 3.5,
			spaceBetween: 20,
		},
		768: {
			slidesPerView: 2.5,
			spaceBetween: 20,
		},
		640: {
			slidesPerView: 2,
			spaceBetween: 20,
		},
		320: {
			slidesPerView: 1.4,
			spaceBetween: 20,
		},
	},
});

const shopSlider = new Swiper(".shopSlider.swiper-container", {
	navigation: {
		nextEl: ".swiper-button-next",
		prevEl: ".swiper-button-prev",
	},
	pagination: {
		el: ".swiper-pagination",
		type: "bullets",
	},
	loop: true,
	centeredSlides: true,
	autoplay: {
		delay: 3000,
	},
	autoHeight: false,
	grabCursor: true,
	breakpoints: {
		1200: {
			slidesPerView: 3.5,
			spaceBetween: 30,
		},
		992: {
			slidesPerView: 3.5,
			spaceBetween: 30,
		},
		768: {
			slidesPerView: 2.5,
			spaceBetween: 30,
		},
		640: {
			slidesPerView: 2,
			spaceBetween: 20,
		},
		320: {
			slidesPerView: 1.4,
			spaceBetween: 20,
		},
	},
});
